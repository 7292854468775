
import { Base64 } from 'js-base64';
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationDocMixin from '@/mixins/data-operations/doc/configuration-doc-mixin';

import { tableToStorageConfs } from '@/store/modules/easy-firestore/table-to-storage-confs';
import { tableToStorageConfArchive } from '@/store/modules/easy-firestore/table-to-storage-conf-archive';
import { TaskListingExpandableProps } from '@/components/data-operations/common/item/tasks/TaskListingExpandable.vue';
import { CONFIGURATIONS } from '@/constants/data-operations/status';
import VersionDocMixin from '@/mixins/data-operations/doc/version-doc-mixin';

@Component
export default class TableToStorageConfigurationsItemView extends Mixins(
	HeaderInfosMixin,
	VersionDocMixin,
	ConfigurationDocMixin
) {
	moduleName: string = tableToStorageConfs.moduleName;
	archivedConfsModuleName: string = tableToStorageConfArchive.moduleName;

	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		let tab = [this.configurationTab, this.fullJSONTab, this.notesTab] as any;
		if (this.item.tasks && this.item.tasks.length) {
			const taskTab = {
				label: 'Tasks',
				href: 'tasks',
				component: {
					name: 'task-listing',
					props: {
						tasks: this.item.tasks,
					},
				},
			};
			tab.splice(1, 0, taskTab);
		}
		return tab;
	}

	get configurationData() {
		if (this.isLegacy(this.item)) {
			return this.configurationDataLegacy;
		} else if (this.itemVersion(this.item) === '3') {
			return this.configurationDataV3;
		} else {
			return this.configurationDataDefault(this.item);
		}
	}

	get configurationDataLegacy() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: 'tableToStorageConfs',
					activeHeader: true,
					viewId: this.itemId,
					viewType: 'conf',
					showDagLaunch: true,
					description: null,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item),
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Destination Storage',
					description: 'Destination Storage of the file to export',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '100px',
						},
						{
							label: 'Storage ID',
							field: 'gcs_dest_bucket',
							width: '200px',
						},
						{
							label: 'Destination Folder',
							field: 'gcs_dest_prefix',
							width: '200px',
						},
						{
							label: 'Output Filename',
							field: 'output_filename',
							width: '200px',
						},
					],
					rows: [
						{
							source_type: 'GCS',
							gcs_dest_bucket: this.item.gcs_dest_bucket,
							gcs_dest_prefix: this.item.gcs_dest_prefix,
							output_filename: this.item.output_filename,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'File Parameters',
					tooltip: true,
					description: 'Parameters for the exported file',
					paramItems: [
						{
							id: 'compression',
							label: 'Compressed',
							value: this.item.compression,
							default: 'None',
						},
						{
							id: 'field_delimiter',
							label: 'Field Delimiter',
							value: this.item.field_delimiter,
							default: '|',
						},
						{
							id: 'delete_dest_bucket_content',
							label: 'Delete Destination Storage Content',
							value: this.item.delete_dest_bucket_content,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Source Table',
					tooltip: true,
					description: 'SQL executed to generate the dataset to export into file',
					paramItems: [
						{
							id: 'gcp_project',
							label: 'Bigquery Project ID',
							value: this.item.gcp_project,
						},
						{
							id: '',
							label: 'SQL File',
							component: 'sql-viewer',
							properties: {
								id: this.item.id,
								sql: this.item.sql ? Base64.decode(Base64.decode(this.item.sql.toBase64())) : null,
							},
						},
						{
							id: 'copy_table',
							label: 'Keep Table',
							value: this.item.copy_table,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				displayCondition: this.item.copy_table,
				props: {
					tableTitle: 'Destination Table',
					description: 'The Destination Table where the dataset will be keeped',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '150px',
						},
						{
							label: 'Project ID',
							field: 'dest_gcp_project_id',
						},
						{
							label: 'Dataset',
							field: 'dest_gbq_dataset',
						},
						{
							label: 'Table Name',
							field: 'dest_gbq_table',
						},
						{
							label: 'Table Suffix',
							field: 'dest_gbq_table_suffix',
						},
					],
					rows: [
						{
							source_type: 'BigQuery',
							dest_gcp_project_id: this.item.dest_gcp_project_id,
							dest_gbq_dataset: this.item.dest_gbq_dataset,
							dest_gbq_table: this.item.dest_gbq_table,
							dest_gbq_table_suffix: this.item.dest_gbq_table_suffix,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(this.item.update_date || this.item.updated_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.updated_by,
						},
					],
				},
			},
		];
	}

	get configurationDataV3() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: 'tableToStorageConfs',
					activeHeader: true,
					viewId: this.itemId,
					viewType: 'conf',
					showDagLaunch: true,
					description: null,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(this.item),
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Default Destination Storage',
					description: 'Default Destination Storage of the file to export',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '100px',
						},
						{
							label: 'Storage ID',
							field: 'gcs_dest_bucket',
							width: '200px',
						},
						{
							label: 'Destination Folder',
							field: 'gcs_dest_prefix',
							width: '200px',
						},
						{
							label: 'Project ID',
							field: 'gcp_project_id',
							width: '200px',
						},
					],
					rows: [
						{
							source_type: 'GCS',
							gcs_dest_bucket: this.item.gcs_dest_bucket,
							gcs_dest_prefix: this.item.gcs_dest_prefix,
							gcp_project_id: this.item.gcp_project_id,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Default File Parameters',
					tooltip: true,
					description: 'Default Parameters for the exported file',
					paramItems: [
						{
							id: 'print_header',
							label: 'Print Header',
							value: this.item.print_header,
							default: true,
						},
						{
							id: 'generate_top_file',
							label: 'Generate Top File',
							value: this.item.generate_top_file,
							default: false,
						},
						{
							id: 'delete_dest_bucket_content',
							label: 'Delete Dest Bucket',
							value: this.item.delete_dest_bucket_content,
							default: false,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				displayCondition: this.item.copy_table,
				props: {
					groupTitle: 'Default Copy Table Parameters',
					paramItems: [
						{
							id: 'copy_table',
							label: 'Copy Table',
							value: this.item.copy_table,
						},
						{
							id: 'dest_gcp_project_id',
							label: 'Dest GCP Project ID',
							value: this.item.dest_gcp_project_id,
						},
						{
							id: 'dest_gbq_dataset',
							label: 'Dest GBQ Dataset',
							value: this.item.dest_gbq_dataset,
						},
						{
							id: 'dest_gbq_table_suffix',
							label: 'Dest GBQ Table Suffix',
							value: this.item.dest_gbq_table_suffix,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				displayCondition: !this.item.copy_table,
				props: {
					groupTitle: 'Default Copy Table Parameters',
					paramItems: [
						{
							id: 'copy_table',
							label: 'Copy Table',
							value: 'false',
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(this.item.update_date || this.item.updated_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.updated_by,
						},
					],
				},
			},
		];
	}
}
